<template>
<v-col>
  <v-row style="margin-bottom:100px;">
    <v-col cols="12" sm="9" md="10" lg="7" class="mx-auto header-section">
      <v-row>

        <v-col cols="12" class="text-center mx-auto header-section">
          <router-link to="/game/battle/menu" style="text-decoration:none;">
            <h2 class="mt-1 ml-4 primary--text">Bataille navale</h2>
          </router-link>
          <h3 class="mt-0 ml-4 primary--text text--darken-3">~ ~ ~<br>Classement général</h3>
        </v-col>
        
        <v-col cols="12" sm="11" md="10" lg="8" class="mx-auto" v-if="ranking != null"
                style="border-top:1px dashed #fbc02d;margin-top:60px;">                
          <template v-for="(userRank, rank) in ranking">
            <v-card :key="userRank._id" class="mt-4 pb-3">
              <v-toolbar class="font-papyrus">
                <v-icon large color="primary" class="mr-1 mb-2">mdi-trophy</v-icon>
                #{{ rank +1 }} • {{ userRank.name }} 
                <v-spacer/>
                <v-icon small color="primary" class="mr-2 mb-2">mdi-hand-coin</v-icon>
                <span class="font-led primary--text">{{ userRank.gameScores.battle.totalMonyBalance }} PRT</span>
                <v-btn fab icon x-small color="blue" class="ml-2 mb-1" 
                        @click="$store.dispatch('app/openConv', { userId: userRank._id })"
                        v-if="userRank._id != $store.state.auth.user._id">
                  <v-icon >mdi-message-outline</v-icon>
                </v-btn>
              </v-toolbar>
              
              <v-simple-table dense>
                
                <tbody>
                  <tr>
                    <td>Total de partie gagnée(s)</td>
                    <td>{{ userRank.gameScores.battle.totalGameWin }}</td>
                  </tr>
                  <tr>
                    <td>Total de partie perdue(s)</td>
                    <td>{{ userRank.gameScores.battle.totalGameLost }}</td>
                  </tr>
                  <tr>
                    <td>Total de PRT gagné(s)</td>
                    <td>{{ userRank.gameScores.battle.totalMonyWin }} PRT</td>
                  </tr>
                  <tr>
                    <td>Total de PRT perdu(s)</td>
                    <td>{{ userRank.gameScores.battle.totalMonyLost }} PRT</td>
                  </tr>
                </tbody>
              </v-simple-table>
              
            </v-card>
          </template>
          <template v-if="ranking.length == 0">
            <span class="font-papyrus text-center d-block mt-2">
              Aucun classement pour l'instant.<br>
              Soyez le premier à jouer <template v-if="!$store.state.auth.isLogin">en créant votre compte</template> !
            </span>
          </template>
        </v-col>

      </v-row>
    </v-col>
  </v-row>
</v-col>
</template>

<style></style>

<script>

import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'


export default {
  name: 'empty',
  components: {  },
  data: () => ({
    ranking: null
    
  }),
  async mounted(){
    this.loadRanking()
  },
  methods: {
    loadRanking(){
      axios.get('/game/battle/ranking')
           .then((res)=>{
            if(res.data.error == false && res.data.ranking != null){
              this.ranking = res.data.ranking
              console.log("ranking", this.ranking)
            }
           })

    },

  },
  computed: {
    
    
  }
}
</script>
